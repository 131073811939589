import { lazy } from "react";

const tags = {
  "[data-tag='app']": lazy(() => import("./routes")),
  "[data-tag='sign-up-trigger']": lazy(() =>
    import("./routes/sign-up/trigger")
  ),
};

export default tags;
