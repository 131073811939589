import "./polyfill.js";
import { Suspense, lazy } from "react";
import sentinel from "sentinel-js";
import tags from "./tags";
import ReactDOM from "react-dom";
import { USE_MOCK } from "./env";
const AppProvider = lazy(() => import("./components/AppProvider"));

function reRenderAll(tags) {
  for (let [name, Component] of Object.entries(tags)) {
    for (let node of Array.from(document.querySelectorAll(name))) {
      ReactDOM.unmountComponentAtNode(node);
      render(node, Component);
    }
  }

  sentinel.reset();

  Object.entries(tags).forEach(([selector, component]) => {
    sentinel.on(selector, (element) => {
      render(element, component);
    });
  });
}

if (USE_MOCK) {
  import("./mock").then(() => {
    reRenderAll(tags);
  });
} else {
  reRenderAll(tags);
}

function render(node, Component) {
  const props = Array.from(node.attributes)
    .map((attr) => [attr.name, attr.value])
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  ReactDOM.render(
    <Suspense fallback={null}>
      <AppProvider addStyling={false}>
        <Component {...props} />
      </AppProvider>
    </Suspense>,
    node
  );
}

if (module.hot) {
  module.hot.accept("./tags", () => {
    const tags = require("./tags").default;

    reRenderAll(tags);
  });
}
